import { default as indexXSPR2598hwMeta } from "/builds/stantabcorp/comptabilite/website/pages/index.vue?macro=true";
import { default as acceptable_45use_45policyMcXrNrCsKJMeta } from "/builds/stantabcorp/comptabilite/website/pages/legal/acceptable-use-policy.vue?macro=true";
import { default as cookie_45policybl8rASPRsIMeta } from "/builds/stantabcorp/comptabilite/website/pages/legal/cookie-policy.vue?macro=true";
import { default as legal_45noticeP53e21dtxdMeta } from "/builds/stantabcorp/comptabilite/website/pages/legal/legal-notice.vue?macro=true";
import { default as privacy_45policydGHvGR5Nh4Meta } from "/builds/stantabcorp/comptabilite/website/pages/legal/privacy-policy.vue?macro=true";
import { default as terms_45of_45usea2DFVZtjXRMeta } from "/builds/stantabcorp/comptabilite/website/pages/legal/terms-of-use.vue?macro=true";
export default [
  {
    name: indexXSPR2598hwMeta?.name ?? "index",
    path: indexXSPR2598hwMeta?.path ?? "/",
    meta: indexXSPR2598hwMeta || {},
    alias: indexXSPR2598hwMeta?.alias || [],
    redirect: indexXSPR2598hwMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSPR2598hwMeta?.name ?? "index___en",
    path: indexXSPR2598hwMeta?.path ?? "/en",
    meta: indexXSPR2598hwMeta || {},
    alias: indexXSPR2598hwMeta?.alias || [],
    redirect: indexXSPR2598hwMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSPR2598hwMeta?.name ?? "index___fr",
    path: indexXSPR2598hwMeta?.path ?? "/fr",
    meta: indexXSPR2598hwMeta || {},
    alias: indexXSPR2598hwMeta?.alias || [],
    redirect: indexXSPR2598hwMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: acceptable_45use_45policyMcXrNrCsKJMeta?.name ?? "legal-acceptable-use-policy",
    path: acceptable_45use_45policyMcXrNrCsKJMeta?.path ?? "/legal/acceptable-use-policy",
    meta: acceptable_45use_45policyMcXrNrCsKJMeta || {},
    alias: acceptable_45use_45policyMcXrNrCsKJMeta?.alias || [],
    redirect: acceptable_45use_45policyMcXrNrCsKJMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: acceptable_45use_45policyMcXrNrCsKJMeta?.name ?? "legal-acceptable-use-policy___en",
    path: acceptable_45use_45policyMcXrNrCsKJMeta?.path ?? "/en/legal/acceptable-use-policy",
    meta: acceptable_45use_45policyMcXrNrCsKJMeta || {},
    alias: acceptable_45use_45policyMcXrNrCsKJMeta?.alias || [],
    redirect: acceptable_45use_45policyMcXrNrCsKJMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policybl8rASPRsIMeta?.name ?? "legal-cookie-policy",
    path: cookie_45policybl8rASPRsIMeta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policybl8rASPRsIMeta || {},
    alias: cookie_45policybl8rASPRsIMeta?.alias || [],
    redirect: cookie_45policybl8rASPRsIMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policybl8rASPRsIMeta?.name ?? "legal-cookie-policy___en",
    path: cookie_45policybl8rASPRsIMeta?.path ?? "/en/legal/cookie-policy",
    meta: cookie_45policybl8rASPRsIMeta || {},
    alias: cookie_45policybl8rASPRsIMeta?.alias || [],
    redirect: cookie_45policybl8rASPRsIMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: legal_45noticeP53e21dtxdMeta?.name ?? "legal-legal-notice",
    path: legal_45noticeP53e21dtxdMeta?.path ?? "/legal/legal-notice",
    meta: legal_45noticeP53e21dtxdMeta || {},
    alias: legal_45noticeP53e21dtxdMeta?.alias || [],
    redirect: legal_45noticeP53e21dtxdMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: legal_45noticeP53e21dtxdMeta?.name ?? "legal-legal-notice___en",
    path: legal_45noticeP53e21dtxdMeta?.path ?? "/en/legal/legal-notice",
    meta: legal_45noticeP53e21dtxdMeta || {},
    alias: legal_45noticeP53e21dtxdMeta?.alias || [],
    redirect: legal_45noticeP53e21dtxdMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policydGHvGR5Nh4Meta?.name ?? "legal-privacy-policy",
    path: privacy_45policydGHvGR5Nh4Meta?.path ?? "/legal/privacy-policy",
    meta: privacy_45policydGHvGR5Nh4Meta || {},
    alias: privacy_45policydGHvGR5Nh4Meta?.alias || [],
    redirect: privacy_45policydGHvGR5Nh4Meta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policydGHvGR5Nh4Meta?.name ?? "legal-privacy-policy___en",
    path: privacy_45policydGHvGR5Nh4Meta?.path ?? "/en/legal/privacy-policy",
    meta: privacy_45policydGHvGR5Nh4Meta || {},
    alias: privacy_45policydGHvGR5Nh4Meta?.alias || [],
    redirect: privacy_45policydGHvGR5Nh4Meta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45usea2DFVZtjXRMeta?.name ?? "legal-terms-of-use",
    path: terms_45of_45usea2DFVZtjXRMeta?.path ?? "/legal/terms-of-use",
    meta: terms_45of_45usea2DFVZtjXRMeta || {},
    alias: terms_45of_45usea2DFVZtjXRMeta?.alias || [],
    redirect: terms_45of_45usea2DFVZtjXRMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45usea2DFVZtjXRMeta?.name ?? "legal-terms-of-use___en",
    path: terms_45of_45usea2DFVZtjXRMeta?.path ?? "/en/legal/terms-of-use",
    meta: terms_45of_45usea2DFVZtjXRMeta || {},
    alias: terms_45of_45usea2DFVZtjXRMeta?.alias || [],
    redirect: terms_45of_45usea2DFVZtjXRMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/website/pages/legal/terms-of-use.vue").then(m => m.default || m)
  }
]