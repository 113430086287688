<template>
    <div>
        <header>
            <v-container>
                <NuxtLink :to="localePath('index')"><img alt="Accounting" class="mt-10 mb-5 logo"
                                                         src="~/assets/img/a.png"></NuxtLink>
                <h1 class="mb-5 hero">{{ $t('home.landing.title') }}</h1>
                <p class="mb-5">{{ $t('home.landing.text') }}</p>
                <div class="mb-8">
                    <v-btn class="background-gradient" href="#plans">
                        {{ $t('home.landing.try') }}
                    </v-btn>
                    <v-btn class="ml-5" href="https://dashboard.accounting.sh" variant="text">{{
                            $t('menu.login')
                        }}
                    </v-btn>
                </div>

                <img alt="" aria-hidden="true" class="hero" src="~/assets/img/home_illustration.svg">
            </v-container>
        </header>
        <!--        <div class="about pt-16 pb-16">-->
        <!--            <v-container>-->
        <!--                <h2><span class="highlight-container yellow"><span class="highlight">Dites adieu à la paperasse!</span></span>-->
        <!--                    Créez des factures et devis percutants-->
        <!--                    en un clin d'œil.</h2>-->

        <!--                <v-row>-->
        <!--                    <v-col md="6" cols="12">A</v-col>-->
        <!--                    <v-col md="6" cols="12">B</v-col>-->
        <!--                </v-row>-->

        <!--                <h2>Gérez vos dépenses sans effort! Une <span class="highlight-container primary"><span-->
        <!--                    class="highlight">vue claire</span></span>-->
        <!--                    de vos-->
        <!--                    finances à tout-->
        <!--                    moment.</h2>-->
        <!--                <h2>Plus de saisies manuelles! Connectez-vous directement à vos comptes bancaires pour une <span-->
        <!--                    class="highlight-container gradient"><span class="highlight">gestion sans-->
        <!--                    accroc</span></span>.</h2>-->
        <!--                <h2>Collaborez sans limites! <span class="highlight-container secondary"><span class="highlight">Invitez toute votre équipe</span></span>-->
        <!--                    avec des-->
        <!--                    permissions sur mesure.</h2>-->
        <!--                <h2><span class="highlight-container"><span-->
        <!--                    class="highlight">Un compte, plusieurs sociétés!</span></span> Gérez l'ensemble de vos activités-->
        <!--                    depuis un seul endroit.</h2>-->
        <!--                <h2>Économisez du temps précieux! Laissez notre fonction de <span class="highlight-container"><span-->
        <!--                    class="highlight">réconciliation automatique</span></span>-->
        <!--                    simplifier vos-->
        <!--                    tâches comptables.</h2>-->
        <!--                <h2>Adieu les tracas des <span class="highlight-container"><span-->
        <!--                    class="highlight">notes de frais!</span></span> Soumission, suivi, approbation –-->
        <!--                    tout en un.</h2>-->
        <!--                <h2>Intégration sans faille! Connectez notre logiciel à vos outils préférés avec notre <span-->
        <!--                    class="highlight-container"><span class="highlight">API et-->
        <!--                    Webhook</span></span>.</h2>-->
        <!--                <h2>Centralisez, organisez, réussissez! La <span class="highlight-container"><span class="highlight">gestion des documents</span></span>-->
        <!--                    à portée-->
        <!--                    de clic.</h2>-->
        <!--                <h2>Votre comptabilité, votre style! Générez des <span class="highlight-container"><span-->
        <!--                    class="highlight">exports personnalisés</span></span>-->
        <!--                    pour une gestion à votre-->
        <!--                    image.</h2>-->
        <!--                <h2>Un monde, une solution! Simplifiez la <span class="highlight-container"><span class="highlight">gestion de la TVA</span></span>-->
        <!--                    avec notre-->
        <!--                    API internationale.</h2>-->
        <!--                <h2><span class="highlight-container"><span class="highlight">Transactions internationales</span></span>-->
        <!--                    sans soucis! Notre API convertit les-->
        <!--                    devises automatiquement.</h2>-->
        <!--                <h2>Conformité mondiale, <span class="highlight-container"><span-->
        <!--                    class="highlight">simplicité locale</span></span>! Attribuez automatiquement les-->
        <!--                    numéros de TVA par pays.</h2>-->
        <!--                <h2>Marquez votre identité! <span class="highlight-container"><span-->
        <!--                    class="highlight">Personnalisez</span></span> factures, devis et documents à-->
        <!--                    votre image unique.</h2>-->
        <!--                <h2>Dites adieu à la saisie manuelle! Importez vos factures d'achat en un éclair grâce à notre OCR-->
        <!--                    alimenté par <span class="highlight-container"><span-->
        <!--                        class="highlight">l'intelligence artificielle</span></span>. Simplifiez, automatisez,-->
        <!--                    réussissez.</h2>-->
        <!--            </v-container>-->
        <!--        </div>-->
        <div id="plans" class="plans__section pt-16 pb-16">
            <v-container>
                <h2 class="text-center">{{ $t('home.plans.choose') }}</h2>

                <div class="plans mt-16 mb-16">
                    <div v-for="(item, index) in plans" :key="index" class="plan">
                        <div class="header">
                            <h4>{{ item.name }}</h4>
                            <h2 :class="item.prominent ? 'text-gradient' : ''">{{ item.price }}</h2>
                        </div>
                        <div class="body">
                            <p v-if="item.subtitle">{{ item.subtitle }}</p>
                            <div v-for="(feature, i) in item.features" :key="i">
                                <div>
                                    <v-icon class="text-gradient">mdi-check-bold</v-icon>
                                </div>
                                <div class="pl-2">{{ feature }}</div>
                            </div>
                        </div>
                        <div class="actions">
                            <v-btn :class="item.prominent ? 'background-gradient' : ''" append-icon="mdi-arrow-right"
                                   :href="item.action" block="">
                                {{ $t('home.plans.subscribe') }}
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!--                <div class="d-flex justify-center">-->
                <!--                    <v-btn :to="localePath('pricing')" append-icon="mdi-chevron-right" variant="plain">-->
                <!--                        {{ $t('home.plans.learn_more') }}-->
                <!--                    </v-btn>-->
                <!--                </div>-->

            </v-container>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "index",
    data() {
        return {
            plans: []
        }
    },
    mounted() {
        const i18nHead = useLocaleHead({
            addSeoAttributes: {
                // canonicalQueries: ['foo']
            }
        })
        useHead({
            htmlAttrs: {
                lang: i18nHead.value.htmlAttrs!.lang
            },
            link: [...(i18nHead.value.link || [])],
            meta: [...(i18nHead.value.meta || [])],
            title: this.$t('home.title')
        })

        this.plans = [
            {
                name: this.$t("plans.free.title"),
                price: "0 €",
                subtitle: null,
                prominent: false,
                features: [
                    this.$t("plans.free.unlimited_users"),
                    this.$t("plans.free.unlimited_invoices"),
                    this.$t("plans.free.unlimited_bills"),
                    this.$t("plans.free.unlimited_quotes"),
                    this.$t("plans.free.unlimited_receipts"),
                    this.$t("plans.free.unlimited_accounts"),
                    this.$t("plans.free.unlimited_transactions"),
                    this.$t("plans.free.unlimited_transfers"),
                    this.$t("plans.free.api"),
                    this.$t("plans.free.webhooks"),
                ],
                action: "https://my.stantabcorp.com/products/accounting/subscribe/3"
            },
            {
                name: this.$t("plans.pro.title"),
                price: "11,99 €",
                subtitle: this.$t("plans.pro.subtitle"),
                prominent: true,
                features: [
                    this.$t("plans.pro.bank_connection"),
                    this.$t("plans.pro.email"),
                    this.$t("plans.pro.personalisation"),
                    this.$t("plans.pro.document_processing"),
                    this.$t("plans.pro.expense_reports"),
                ],
                action: "https://my.stantabcorp.com/products/accounting/subscribe/1"
            }
        ]
    }
};
</script>

<script lang="ts" setup>
const localePath = useLocalePath()
</script>

<style scoped>
header {
    min-height: 100vh;
    background: rgb(33, 33, 33);
    color: #fff;
    text-align: center;
    overflow: hidden;
}

.plans__section {
    background: #1A2975;
    color: #fff;
}

header .logo {
    width: 100px;
    height: 100px;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
    padding-bottom: 20px;
    line-height: 3rem;
}

p {
    padding-bottom: 15px;
}

.about {
    min-height: 100vh;
}

.plans {
    display: flex;
    justify-content: center;
}

.plan {
    background: #fff;
    color: #000;
    height: auto;
    padding: 24px;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    width: 324px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plan .header {
    text-align: center;
    padding-bottom: 16px;
    background-color: #ffffff;
}

.plan .body {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: fit-content;
    padding-bottom: 24px;
}

.plan .body > div {
    display: flex;
    justify-content: start;
    width: fit-content;
    text-align: left;
}

.plan:first-of-type {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.plan:last-of-type:not(:nth-of-type(2)) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.plan:nth-of-type(2) {
    z-index: 2;
    border-radius: 10px;
}

@media (max-width: 800px) {
    .plans {
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px;
    }

    .plan {
        border-radius: 10px;
        width: 100%;
    }

    h1.hero {
        font-size: 3rem;
    }
}

img.hero {
    max-width: 100%;
}

.about h2 {
    text-align: center;
    font-size: 30px;
}

.highlight-container, .highlight {
    position: relative;
}

.highlight-container {
    display: inline-block;
}

.highlight-container:before {
    content: " ";
    display: block;
    height: 90%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    top: -1px;
    left: -1px;
}

.highlight-container.yellow:before {
    background: #ffd500;
    transform: rotate(-3deg);
    border-radius: 12% 57% 58% 100%;
    padding: 10px 11px 18px 20px;
}

.highlight-container.primary:before {
    background: #1A2975;
    transform: rotate(-2deg);
    border-radius: 30% 64% 85% 86%;
    padding: 5px 7px 18px 24px;
}

.highlight-container.gradient:before {
    background-image: linear-gradient(310deg, #1a2975, #567eea);
    transform: rotate(-1deg);
    border-radius: 32% 48% 51% 60%;
    padding: 3px 5px 7px 23px;
}

.highlight-container.secondary:before {
    background: #567eea;
    transform: rotate(-2deg);
    border-radius: 18% 32% 40% 46%;
    padding: 4px 8px 12px 15px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(3deg);
    border-radius: 27% 32% 69% 77%;
    padding: 8px 18px 23px 25px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(3deg);
    border-radius: 11% 21% 42% 50%;
    padding: 9px 17px 23px 25px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(1deg);
    border-radius: 11% 41% 46% 98%;
    padding: 15px 17px 19px 21px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(4deg);
    border-radius: 12% 56% 88% 100%;
    padding: 15px 21px 23px 24px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(0deg);
    border-radius: 41% 17% 20% 11%;
    padding: 3px 4px 24px 25px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(-1deg);
    border-radius: 12% 41% 89% 96%;
    padding: 8px 10px 13px 24px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(2deg);
    border-radius: 41% 82% 87% 92%;
    padding: 4px 6px 13px 23px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(-1deg);
    border-radius: 17% 63% 91% 95%;
    padding: 5px 20px 22px 24px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(1deg);
    border-radius: 20% 35% 45% 46%;
    padding: 6px 7px 12px 22px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(4deg);
    border-radius: 25% 61% 65% 66%;
    padding: 4px 9px 10px 12px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(-2deg);
    border-radius: 11% 17% 29% 34%;
    padding: 7px 10px 13px 22px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(-1deg);
    border-radius: 19% 46% 66% 93%;
    padding: 5px 10px 12px 22px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(0deg);
    border-radius: 36% 42% 46% 88%;
    padding: 12px 16px 21px 24px;
}

.highlight-container.red:before {
    background: red;
    transform: rotate(-1deg);
    border-radius: 17% 48% 89% 91%;
    padding: 7px 11px 16px 23px;
}

/*

*/
.highlight-container.primary, .highlight-container.gradient {
    color: #fff;
}

</style>