export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://analytics.stc.onl"},{"rel":"preconnect","href":"https://fonts.gstatic.com"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Anybody&family=IBM+Plex+Sans:wght@500&family=Noto+Color+Emoji&display=swap"}],"style":[],"script":[{"src":"https://analytics.stc.onl/api/static/analytics.js","data-site":"7fa03167-f3fe-4607-b7f0-13628f48f4f9","defer":""}],"noscript":[],"titleTemplate":"%s | Accounting","charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'