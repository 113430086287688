<template>
    <v-footer>
        <v-row justify="center" no-gutters>
            <div
                v-for="link in links"
                :key="link"
            >
                <v-btn
                    v-if="link.to.startsWith('http')"
                    :href="link.to"
                    class="mx-2"
                    rounded="xl"
                    variant="text"
                >
                    {{ $t(link.text) }}
                </v-btn>
                <v-btn
                    v-else
                    :to="localePath(link.to)"
                    class="mx-2"
                    rounded="xl"
                    variant="text"
                >
                    {{ $t(link.text) }}
                </v-btn>
            </div>
            <v-col class="text-center mt-4" cols="12">
                {{ new Date().getFullYear() }} — <strong>STAN-TAB CORP. LTD</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: "FooterNav",
    data: () => ({
        links: [
            // {text: "menu.documentation", to: "https://docs.accounting.sh"},
            {text: "menu.login", to: "https://dashboard.accounting.sh"},
            {text: "my stantabcorp", to: "https://my.stantabcorp.com"},
            {text: "menu.terms", to: "/legal/terms-of-use"},
            {text: "menu.privacy", to: "/legal/privacy-policy"},
            {text: "menu.notice", to: "/legal/legal-notice"},
            {text: "menu.contact", to: "https://stantabcorp.com/contact-us"}
            // {text: "menu.status", to: "https://status.stantabcorp.net/status/accounting"}
        ]
    })
};
</script>

<script setup>
const localePath = useLocalePath();
</script>

<style scoped>

</style>