<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Navbar",
    methods: {
        getFlag(code) {
            switch (code) {
                case "fr":
                    return "🇫🇷";
                case "en":
                default:
                    return "🇬🇧";

            }
        }
    }
})
</script>

<script lang="ts" setup>
const localePath = useLocalePath()
const switchLocalePath = useSwitchLocalePath()
</script>

<template>
    <v-app-bar theme="dark">
        <template v-slot:prepend>
            <NuxtLink :to="localePath('index')">
                <img
                    alt="Accounting"
                    src="~/assets/img/a.png"
                    width="34"
                />
            </NuxtLink>
        </template>

        <v-app-bar-title>
            <NuxtLink :to="localePath('index')">Accounting</NuxtLink>
        </v-app-bar-title>

        <template v-slot:append>
            <!--            <v-btn :to="localePath('pricing')" class="mr-2" variant="plain">{{ $t('menu.pricing') }}</v-btn>-->
            <v-btn append-icon="mdi-login" class="mr-2" href="https://dashboard.accounting.sh" variant="outlined">
                {{ $t('menu.login') }}
            </v-btn>
            <v-menu
                open-on-hoverclass="mr-2"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        append-icon="mdi-chevron-down"
                        v-bind="props"
                    >
                        <span class="emoji">{{ getFlag($i18n.locale) }}</span>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                        v-for="(item, index) in $i18n.locales"
                        :key="index"
                        :to="switchLocalePath(item.code)"
                    >
                        <v-list-item-title><span class="emoji">{{ getFlag(item.code) }}</span> <span
                            class="ml-3">{{ $t('langs.' + item.code) }}</span></v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </v-app-bar>
</template>

<style scoped>
a {
    color: inherit;
    text-decoration: none;
}
</style>