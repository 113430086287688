<script>
export default {
    name: "home",
    mounted() {
        const script = document.createElement("script");
        script.innerHTML = `(function(d,t) {
    var BASE_URL="https://support.stantabcorp.com";
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=BASE_URL+"/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
        window.chatwootSDK.run({
            websiteToken: 'G2LPkUqag6YEQd6eSYvpdkSN',
            baseUrl: BASE_URL
        })
    }
})(document,"script");`;
        document.body.appendChild(script);
    }
};
</script>

<template>
    <Navbar></Navbar>
    <v-main>
        <slot></slot>
    </v-main>
    <FooterNav></FooterNav>
</template>

<style scoped>

</style>


<!--<script>-->
<!--(function(d,t) {-->
<!--    var BASE_URL="https://support.stantabcorp.com";-->
<!--    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];-->
<!--    g.src=BASE_URL+"/packs/js/sdk.js";-->
<!--    g.defer = true;-->
<!--    g.async = true;-->
<!--    s.parentNode.insertBefore(g,s);-->
<!--    g.onload=function(){-->
<!--        window.chatwootSDK.run({-->
<!--            websiteToken: 'G2LPkUqag6YEQd6eSYvpdkSN',-->
<!--            baseUrl: BASE_URL-->
<!--        })-->
<!--    }-->
<!--})(document,"script");-->
<!--</script>-->